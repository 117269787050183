import axios from "axios";

// const API_BASE_URL = "http://localhost:8001/api/v1/";
const API_BASE_URL = 'https://api-karir.citsm.id/api/v1/';
// const API_BASE_URL = "https://be-puskarier.herokuapp.com/api/v1/";
// const API_BASE_URL = "https://be-puskarier.000webhostapp.com/api/v1/";

export async function apiDeleteWithAuth(queryString) {
  const token = localStorage.getItem("token");
  const configBearer = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.delete(
    `${API_BASE_URL}${queryString}`,
    configBearer
  );
  return response;
}

export async function apiGetWithAuth(queryString) {
  const token = localStorage.getItem("token");

  const controller = new AbortController();

  const configBearer = {
    headers: { Authorization: `Bearer ${token}`, signal: controller.signal },
  };

  const response = await axios.get(
    `${API_BASE_URL}${queryString}`,
    configBearer
  );
  // console.log(controller);
  controller.abort();
  return response;
}
export function apiGetWithAuth2(queryString) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    const controller = new AbortController();

    const configBearer = {
      headers: { Authorization: `Bearer ${token}`, signal: controller.signal },
    };

    axios
      .get(`${API_BASE_URL}${queryString}`, configBearer)
      .then((response) => resolve(response));
    controller.abort();
  });
}

export async function apiGet(queryString) {
  const response = await axios.get(`${API_BASE_URL}${queryString}`);
  return response;
}
export async function apiGetWithUrl(queryString) {
  const response = await axios.get(queryString);
  return response;
}

export async function apiPost(queryString, dataPost) {
  const response = await axios.post(`${API_BASE_URL}${queryString}`, dataPost);
  return response;
}

export async function apiPostWithAuth(queryString, dataPost) {
  const token = localStorage.getItem("token");
  const configBearer = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${API_BASE_URL}${queryString}`,
    dataPost,
    configBearer
  );
  return response;
}

export async function apiPutWithAuth(queryString, dataPost) {
  const token = localStorage.getItem("token");
  const configBearer = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${API_BASE_URL}${queryString}`,
    dataPost,
    configBearer
  );
  return response;
}
